import "../style.scss";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useToast } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { format } from "date-fns";
import { differenceInYears } from "date-fns";
import { subYears } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Alert from "./Alert";
import { showSSNPage, updateAgentData, updateUnder18 } from "./api";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { OnboardingRouterPath } from "../../routing/constant/onboardingRoute";
import { reloadAgentProfile } from "../../store/session";
import { OIG } from "../constants/OIG_VALIDATION";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { logOnboardingError } from "../util/logging";
import { getOigState } from "../util/oig";
import { fireOnboardingSegmentEvent } from "../util/segment";

const MININUM_VALID_AGE = 18;

const validateDobInput = (dob: Date, currentDatetime: Date) => {
  const age = differenceInYears(currentDatetime, dob);
  return age >= MININUM_VALID_AGE;
};

export function AgentDob(props: OnBoardingComponentProps) {
  const { nextStagePath } = props;

  const { showErrorToast } = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const worker = useDefinedWorker();

  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });

  const invalidAgeModalState = useModalState();
  const notContinueModalState = useModalState();

  const agentTimezone = worker.tmz || "UTC"; // Default to UTC if timezone is not defined
  const currentDatetime = utcToZonedTime(new Date(), agentTimezone);
  const defaultDob = subYears(currentDatetime, MININUM_VALID_AGE);

  const [agentDateOfBirth, setAgentDateOfBirth] = useState<Date>(
    isDefined(worker.dob) ? new Date(worker.dob ?? "") : defaultDob
  );
  const isValidDoB = validateDobInput(agentDateOfBirth, currentDatetime);

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event?.preventDefault();
    setErrorAlert({ hasError: false });
    try {
      setUpdatingData(true);

      const oig = getOigState();

      const response = await updateAgentData({
        stage: ONBOARDING_STAGES.DOB,
        dob: format(agentDateOfBirth, "yyyy-MM-dd"),
        ...(oig?.dobRequired && {
          validationId: oig.validationId,
          requestFurtherValidation: oig.dobRequired,
        }),
      });

      if (isDefined(oig)) {
        if (oig.ssnRequired) {
          delete oig.ssnRequired;
        }

        if (response[OIG]?.requestFurtherValidation) {
          oig["ssnRequired"] = true;
        }

        localStorage.setItem(OIG, JSON.stringify(oig));
      }

      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_DOB, {
        hcpId: worker.userId,
      });
      dispatch(reloadAgentProfile());
      const { showSSNFlag } = await showSSNPage();
      if (showSSNFlag || response[OIG]?.requestFurtherValidation) {
        history.push(`${OnboardingRouterPath.ONBOARDING_SSN}`);
        return;
      }
      history.push(`${nextStagePath}`);
    } catch (error) {
      logOnboardingError(ONBOARDING_STAGES.DOB, (error as Error).message, worker.userId);
      setErrorAlert({ hasError: true, reason: error?.message });
      showErrorToast("Unexpected error occurred while saving dob");
    } finally {
      setUpdatingData(false);
    }
  };

  const closeErrorModal = () => {
    setErrorAlert({ hasError: false });
  };

  const handleConfirmNotValidAge = async () => {
    invalidAgeModalState.closeModal();
    notContinueModalState.openModal();
    await updateUnder18({
      dob: format(agentDateOfBirth, "yyyy-MM-dd"),
      underage: true,
    });
  };

  const handleChangeNotValidAge = async () => {
    invalidAgeModalState.closeModal();
    await updateUnder18({
      dob: format(agentDateOfBirth, "yyyy-MM-dd"),
      underage: false,
    });
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentAddress" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Alert
          isOpen={errorAlert.hasError}
          reason={errorAlert.reason}
          closeAlert={closeErrorModal}
        />

        <IonAlert
          cssClass={"alert-popup-container"}
          mode="ios"
          isOpen={invalidAgeModalState.modalIsOpen}
          backdropDismiss={false}
          header="Are you under 18?"
          subHeader="You’ve selected a date of birth indicating that you are under 18 years old."
          message="Is this correct?"
          buttons={[
            {
              text: "I am under 18",
              handler: handleConfirmNotValidAge,
              cssClass: "positive-action",
            },
            {
              text: "Change my Date of Birth",
              handler: handleChangeNotValidAge,
              cssClass: "neutral-action",
            },
          ]}
        />
        <IonAlert
          cssClass={"alert-popup-container"}
          mode="ios"
          isOpen={notContinueModalState.modalIsOpen}
          backdropDismiss={false}
          header="Sorry, you can’t continue."
          message="Clipboard does not allow individuals under 18 years of age to pick up work on its marketplace."
          buttons={[
            {
              text: "OK",
              handler: () => notContinueModalState.closeModal(),
              cssClass: "positive-action",
            },
          ]}
        />
        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <div className="form-heading">
                <h4>What is your date of birth?</h4>
              </div>
              <div>
                <MobileDatePicker
                  sx={{ width: "100%" }}
                  label="Date of Birth"
                  format="MMMM dd, yyyy"
                  maxDate={new Date(currentDatetime)}
                  value={agentDateOfBirth}
                  onChange={(date) => {
                    if (!isDefined(date)) {
                      return;
                    }
                    setAgentDateOfBirth(date);
                  }}
                  onAccept={(date) => {
                    if (!isDefined(date)) {
                      return;
                    }
                    if (!validateDobInput(date, currentDatetime)) {
                      invalidAgeModalState.openModal();
                    }
                  }}
                />
              </div>
            </form>
          </div>
          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={updatingData || !agentDateOfBirth || !isValidDoB}
              onClick={onNext}
            >
              Continue
              {updatingData && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
}
